import React from "react"

const center = {
  textAlign: "center",
  margin: "50px",
}

const BuyThankYou = () => {
  return (
    <>
      <h2 style={center}>If any advice was useful consider buying me a cup of coffee.</h2>
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick"/>
        <input type="hidden" name="hosted_button_id" value="ZNDW9S8BJ9GGE"/>
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit"
               title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button"/>
        <img alt="" border="0" src="https://www.paypal.com/en_GR/i/scr/pixel.gif" width="1" height="1"/>
      </form>
    </>
  )
}

export default BuyThankYou
