import React from "react"
import SiteLayout from "../components/layouts/SiteLayout"
import SEO from "../components/SEO"
import BuyThankYou from "../components/BuyThankYou"
import Centered from "../components/layouts/ViewportCentered"
import Container from "../components/layouts/container/Container"
import SeoImage from "../images/social-cover.jpg"

const BuyPage = () => (
  <SiteLayout>
    <SEO
      title="Advice worth giving donate"
      description="Consider buying me a coffee"
      image={{ src: `${SeoImage}`, width: 1200, height: 627 }}/>
    <Container>
      <Centered>
        <BuyThankYou/>
      </Centered>
    </Container>
  </SiteLayout>
)

export default BuyPage
